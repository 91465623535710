import React from "react";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import * as CONST from "../../components/const";
import { StaticImage } from "gatsby-plugin-image";
const bg = "../../images/inquiry/Inquiry_org.png";

function IndexPage() {
  const STATIC_IMAGE_PLACEHOLDER = "blurred"; // blurred tracedSVG none
  const MIRGIN = "font-semibold xl:font-normal mt-5 xl:mt-7"
  return (
    <Layout>
      <SEO
        description="ALPHA WAVEのプライバシーポリシーについてです。個人情報の管理・利用目的・第三者への開示・提供の禁止・安全対策、ご本人の照会、法令・規範の遵守と見直しをご覧ください。"
        title="Privacy Policy"
      />

      <section className={CONST.SECTION_P_AREA_CLASS}>
        <StaticImage quality="100" alt="" layout="fullWidth" placeholder={STATIC_IMAGE_PLACEHOLDER} className={CONST.INQUIRY_BACK_GROUND_PATTERN_CLASS} src={bg} />
        <div className="w-full z-10">
          <h1 className={CONST.H1_CLASS + " font-normal"}><span className="inline-block">プライバシー</span><span className="inline-block">ポリシー</span></h1>
          <p>株式会社 アルファ・ウェーブ（以下「当社」）は、以下のとおり個人情報保護方針を定め、個人情報保護の仕組みを構築し、</p>
          <p>全従業員に個人情報保護の重要性の認識と取組みを徹底させることにより、個人情報の保護を推進いたします。</p>

          <p className={MIRGIN}>個人情報の管理</p>
          <p>当社は、個人情報に対して正確かつ最新の状態に保ち、個人情報への不正アクセス・紛失・破損・改ざん・漏洩等を防止するため、</p>
          <p>セキュリティシステムの維持・管理体制の整備・社員教育の徹底等の必要な措置を講じ、安全対策を実施し個人情報の厳重な管理を行ないます。</p>

          <p className={MIRGIN}>個人情報の利用目的</p>
          <p>当社は、当ホームページで取得した個人情報は、当社からのご連絡や業務のご案内やご質問に対する回答として、電子メールや資料のご送付に利用いたします。</p>

          <p className={MIRGIN}>個人情報の第三者への開示・提供の禁止</p>
          <p>当社は、個人情報を適切に管理し、次のいずれかに該当する場合を除き、個人情報を第三者に開示いたしません。</p>
          <p>・ご本人の同意が得られた場合、当社が業務を委託する関係者に対して開示する場合法令に基づき開示することが必要である場合</p>
          <p>・法令に基づき裁判所や警察等の公的機関から要請があった場合</p>
          <p>・法令に特別の規定がある場合</p>
          <p>・ご本人や第三者の生命・身体・財産・名誉を損なうおそれがあり、ご本人の同意を得ることができない場合</p>
          <p>・法令や当社のご利用規約・注意事項に反する行動から、当社の権利、財産またはサービスを保護または防禦する必要があり、ご本人の同意を得ることができない場合</p>

          <p className={MIRGIN}>個人情報の安全対策</p>
          <p>当社は、個人情報の正確性及び安全性確保のために、セキュリティに万全の対策を講じています。</p>

          <p className={MIRGIN}>ご本人の照会</p>
          <p>当社は、ご本人の個人情報の照会・修正・削除等をご希望される場合には、ご本人であることを確認の上、対応させていただきます。</p>

          <p className={MIRGIN}>法令、規範の遵守と見直し</p>
          <p>当社は、保有する個人情報に関して適用される日本の法令、その他規範を遵守するとともに、本ポリシーの内容を適宜見直し、その改善に努めます。</p>
        </div>
      </section>
    </Layout>
  );
}

export default IndexPage;
